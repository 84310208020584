import * as React from "react";
import axios from "axios";
import { push, graphql } from "gatsby";
import Select from "react-select";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Form from "../../components/form/form";
import Spinner from "../../components/spinner/spinner";
import {
  isLoggedIn,
  getUser,
  updateUser,
  getAccessToken,
} from "../../lib/auth";
import { urls } from "../../config";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Card from "./card";
import "./profile.scss";
import AccountLayout from "./account-layout";
import ReactDOM from "react-dom";
import { BrandButton } from "../../lib/components/Button";
import { navigateTo } from "../../lib/UrlUtils";
import Layout from "../../components/layout/layout";
import { useState } from "react";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    "font-family": "Barlow",
    "font-size": "17px",
    "font-weight": "normal",
    "font-style": "normal",
    "font-stretch": "normal",
    "letter-spacing": "normal",
    color: "#000000",
    "vertical-align": "top",
    height: "30px",
    "justify-content": "flex-start",
    div: {
      padding: 0,
    },
    "div:first-child": {
      "flex-grow": "1",
    },
    "div:nth-child(2)": {
      "flex-grow": "4",
      "max-width": "16px",
      // 'svg path': {
      //   fill: 'red',
      // },
    },
    border: 0,
    borderColor: "blue",
    boxShadow: "none",
    "border-radius": 0,
    span: {
      display: "none",
    },
    svg: {
      path: {
        fill: "#000",
      },
      height: "100%",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#353e44",
    backgroundColor: state.isSelected
      ? "#00B985"
      : state.isFocused
        ? "#00B985"
        : "#fff",
  }),
};
export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Profile = ({ data: { ribbon, header, footer } }) => {
  const [user, setUser] = useState(getUser());
  const [newPassword, setNewPassword] = useState("");
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passworderror, setPassworderror] = useState("");
  const [passwordsuccess, setPasswordsuccess] = useState("");
  const [profileerror, setProfileerror] = useState("");
  const [profilesuccess, setProfilesuccess] = useState("");
  const [toast, setToast] = useState("");
  const [update, setUpdate] = useState("");
  const { t, language } = useTranslation();
  const selectRef = React.createRef();
  const formRef = React.createRef();

  const onSubmitNewPassword = () => {
    if (newPassword !== confirmPassword) {
      setPassworderror("unmatch");
      // Handle validation error here.
      return;
    }

    if (!oldPassword) {
      setPassworderror("required");
      return;
    }

    const formData = { oldPassword, newPassword };
    setLoadingPassword(true);
    const _self = this;
    axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      url: `${urls.changePassword}`,
      data: formData,
    })
      .then((res) => {
        if (res.data.error) throw new Error(res.data.error);
        // Handle API error reporting here.
        // alert('OK!' + res.data.result);
        if (res.data.result) {
          // setToast(t("resetToNewPasswordSuccess"));
          setPasswordsuccess(t("resetToNewPasswordSuccess"));
          setPassworderror("");
          setLoadingPassword(false);
          // ReactDOM.findDOMNode(_self).scrollIntoView();
        } else {
          setPassworderror(res.error ? res.error : t("oopsError"));
          setLoadingPassword(false);
          setPasswordsuccess("");
          // setToast("");
        }
      })
      .catch((err) => {
        setPassworderror(t("oopsError"));
        setPasswordsuccess("");
        setLoadingPassword(false);
        // @TODO: get copy for this err msg.
      });
  };

  const onChange = ({ target: { name, type, checked, value } }) => {
    const userUpdated = Object.assign({}, user, {
      [name]: type === "checkbox" ? checked : value,
    });
    setUser(userUpdated);
  };

  const passwordFieldChangeOldPass = (e) => {
    setOldPassword(e.target.value);
  };

  const passwordFieldChangeNewPass = (e) => {
    setNewPassword(e.target.value);
  };

  const passwordFieldChangeConfirmPass = (e) => {
    setConfirmPassword(e.target.value);
  };

  const profileData = {
    title: "Profile",
    edit: "Edit Profile",
    change: "Change Password",
    update: "Update Payment Information",
    firstName: "First Name *",
    lastName: "Last Name",
    gender: "Gender",
    save: "Save Profile",
    saved: "Profile saved!",
    failed: "Profile save failed!",
    cancel: "cancel",
    genderOptions: [
      {
        value: "female",
        label: "Female",
      },
      {
        value: "male",
        label: "Male",
      },
      {
        value: "other",
        label: "Other",
      },
    ],
    fitnessGoalOptions: [
      {
        value: "keepFit",
        label: "Keep Fit",
      },
      {
        value: "haveFun",
        label: "Have Fun",
      },
    ],
  };

  const changePassword = {
    old: "Old Password",
    new: "New Password",
    save: "Save Password",
    repeat: "Repeat Password",
    reset: "Reset Password",
    unmatch: "Passwords do not match",
    required: "Current password is required",
    saved: "Password saved!",
    failed: "Password update failed",
  };

  // Handle submit of updated user profile data.
  const handleSubmit = (e) => {
    e.preventDefault();
    const _self = this;
    setLoadingProfile(true);

    updateUser(user)
      .then(() => {
        setProfilesuccess(profileData.saved);
        setProfileerror("");
        setLoadingProfile(false);
        // ReactDOM.findDOMNode(_self).scrollIntoView();
      })
      .catch((err) => {
        setProfileerror(profileData.failed);
        setProfilesuccess("");
        setLoadingProfile(false);
      });
  };

  const submitPasswordForm = (e) => {
    formRef.current.onSubmit(e);
  };

  if (!isLoggedIn()) return navigateTo("/login");
  const accountData = {
    email: "Email *",
  };

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <AccountLayout currentPage="/account/profile">
        <div className="">
          <Card titleText={t("profile")}>
            <form
              className="profile-content-block__form"
              onSubmit={handleSubmit}
            >
              <Spinner active={loadingProfile} />
              <p className="account__text-input-label">{t("firstNameText")}</p>
              <input
                className="account__text-input-field"
                placeholder=""
                id="firstName"
                name="firstName"
                value={user.firstName}
                onChange={onChange}
              />

              <p className="account__text-input-label">{t("lastNameText")}</p>
              <input
                className="account__text-input-field"
                placeholder=""
                id="lastName"
                name="lastName"
                value={user.lastName}
                onChange={onChange}
              />

              <p className="account__text-input-label">{t("email")}</p>
              <input
                className="account__text-input-field"
                placeholder=""
                name="username"
                value={user.username}
                onChange={onChange}
                disabled="disabled"
              />

              <p className="account__text-input-label">{t("gender")}</p>
              <Select
                className="genders"
                styles={selectStyles}
                ref={selectRef}
                id="gender"
                name="gender"
                isSearchable={false}
                options={profileData.genderOptions}
                onChange={(e) =>
                  onChange({
                    target: { name: "gender", ...e },
                  })
                }
                value={profileData?.genderOptions?.find((option) => {
                  return option.value === user.gender;
                })}
              />
              {profileerror ? (
                <div
                  css={css`
                    height: 48px;
                    font-family: Barlow;
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fc172f;
                  `}
                >
                  {profileerror}
                </div>
              ) : (
                ""
              )}
              {profilesuccess ? (
                <div
                  css={css`
                    height: 48px;
                    font-family: Barlow;
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #00CC83;
                  `}
                >
                  {profilesuccess}
                </div>
              ) : (
                ""
              )}
              <div
                css={css`
                  display: block;
                  /* margin-left: -20px; */
                  @media (max-width: 599px) {
                    display: flex;
                  }
                `}
              >
                <BrandButton
                  className={"header__nav-button button-hover "}
                  css={css`
                    margin-top: 38px;
                  `}
                >
                  {t("saveText")}
                </BrandButton>
              </div>
            </form>
          </Card>

          <Card style={{ marginLeft: "40px" }} titleText={t("resetPassword")}>
            <Spinner active={loadingPassword} />
            <Form
              className="change-password__form"
              onSubmit={onSubmitNewPassword}
              ref={formRef}
            >
              <input
                className="account__text-input-field"
                placeholder={t("oldPasswordText")}
                name="oldPassword"
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
              />
              <input
                className="account__text-input-field"
                placeholder={t("newPasswordText")}
                name="newPassword"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
              <input
                className="account__text-input-field bottom-input"
                placeholder={t("repeatPasswordText")}
                name="confirmPassword"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              {passworderror ? (
                <div
                  css={css`
                    height: 48px;
                    font-family: Barlow;
                    font-size: 12px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 18px;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fc172f;
                  `}
                >
                  {passworderror}
                </div>
              ) : (
                ""
              )}
              {passwordsuccess ? (
                <div
                  css={css`
                    height: 48px;
                    font-family: Barlow;
                    font-size: 12px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 18px;
                    letter-spacing: normal;
                    text-align: left;
                    color: #00CC83;
                  `}
                >
                  {passwordsuccess}
                </div>
              ) : (
                ""
              )}
              <div
                className={"mt-8 change-password__form-controls "}
                css={css`
                  display: block;
                  @media (max-width: 599px) {
                    display: flex;
                  }
                `}
              >
                <BrandButton
                  className={"header__nav-button button-hover"}
                  css={css`
                    margin-top: 12px;
                  `}
                  onClick={submitPasswordForm}
                >
                  {t("saveText")}
                </BrandButton>
              </div>
            </Form>
          </Card>
        </div>
      </AccountLayout>
    </Layout>
  );
};

export default Profile;
