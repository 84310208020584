import * as React from "react";
import Proptypes from "prop-types";
import Select from "react-select";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

const { Provider, Consumer } = React.createContext({});

const ProviderWithRef = React.forwardRef(
  ({ children, value, ...props }, ref) => (
    <Provider value={value}>
      {React.cloneElement(children, { ...props, ref })}
    </Provider>
  )
);

/** Form Component
 *  Context enabled. Use with provided form elements. Doesn't require onChange functions
 *  to be added. return value for onSubmit will be the namekeyed values from the inputs
 *  @example
 *  <Form
 *    onSubmit={() => null}
 *    resetButton={<button>reset form</button>}
 *  >
 *    <Input />
 *    <Input />
 *    <Input />
 *    <Input />
 *    <button>submit</button>
 *  </Form>
 * */
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state.formData);
  }

  onChange({ target: { name, type, checked, value } }) {
    const formData = Object.assign({}, this.state.formData, {
      [name]: type === "checkbox" ? checked : value,
    });
    this.setState({ formData });
  }

  onReset() {
    this.setState({ formData: {} });
  }

  render() {
    const { resetButton, children, ...props } = this.props;
    return (
      <ProviderWithRef
        value={Object.assign({}, this.state.formData, {
          onChange: this.onChange,
          onSelectChange: this.onSelectChange,
        })}
      >
        <form {...props} onSubmit={this.onSubmit}>
          {children}
          {resetButton &&
            React.cloneElement(resetButton, {
              onClick: this.onReset,
              type: "button",
            })}
        </form>
      </ProviderWithRef>
    );
  }
}

Form.propTypes = {
  onSubmit: Proptypes.func.isRequired,
  children: Proptypes.node.isRequired,
  resetButton: Proptypes.node,
};
Form.defaultProps = {
  resetButton: null,
};

const formInputStyles = css`
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d3d6db;
  border-radius: 3px;
  color: #222324;
  line-height: 24px;
  font-size: 16px;
  padding: 3px 3px;
  margin: 5px;
  position: relative;
  vertical-align: top;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const Input = (props) => (
  <Consumer>
    {(context) => (
      <input
        css={formInputStyles}
        {...props}
        onChange={(e) => context.onChange(e)}
        value={context[props.name] || props.value || ""}
      />
    )}
  </Consumer>
);

const composeClassName = (base, classes) =>
  classes ? `${base} ${classes}` : base;

export const TextArea = (props) => (
  <Consumer>
    {(context, className) => (
      <textarea
        css={formInputStyles}
        className={composeClassName(className)}
        {...props}
        onChange={(e) => context.onChange(e)}
        value={context[props.name] || props.value || ""}
      />
    )}
  </Consumer>
);

export const FormSelect = ({ children, value, name, options }) => (
  <Consumer>
    {(context) => (
      <Select
        name={name}
        options={Object.values(options)}
        onChange={(e) => context.onChange({ target: { name, ...e } })}
        value={options[context[name]] || null}
      />
    )}
  </Consumer>
);

export default Form;
