import axios from "axios";
import { api, urls } from "../config";

const isBrowser = typeof window !== `undefined`;

export const getData = () =>
  window.localStorage.__fitplan__
    ? JSON.parse(window.localStorage.__fitplan__)
    : {};

export const getItem = (key) => getData()[key];

export const removeItem = (...args) => {
  const localStorage = getData();
  args.forEach((key) => {
    if (localStorage[key]) {
      delete localStorage[key];
    }
  });
  window.localStorage.__fitplan__ = JSON.stringify(localStorage);
};

export const setData = (dataObj) =>
  (window.localStorage.__fitplan__ = JSON.stringify(
    Object.assign({}, getData(), dataObj)
  ));

export const clearData = () => window.localStorage.removeItem("__fitplan__");

export const getUserData = (access_token) =>
  axios({
    method: "get",
    url: `${urls.getUserProfile}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  }).then((userRes) => {
    return setData({
      user: userRes.data.result,
      access_token,
    });
  });

export const login = (user) => {
  return axios({
    url: `${api.base}${api.login}`,
    method: "post",
    data: user,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }, // url below needs to be in config
  }).then((res) => {
    if (res.data.error) throw new Error("Unauthorized");
    return getUserData(res.data.access_token);
  });
};

export const updateUser = (data) => {
  let body = {
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
  };

  return axios({
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getItem("access_token")}`,
    },
    url: `${urls.profileChange}`,
    data: body,
  }).then((res) => {
    if (res.data.error) throw new Error("Update failed");
    return setData({
      user: data,
    });
  });
};

export const postMessage = (data) => {
  if (!isBrowser) return false;
  return axios({
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: `${api.base}${api.postMessage}`,
    data,
  }).then((res) => {
    if (res.data.error)
      throw new Error(
        res.data.error.message
          ? res.data.error.message
          : "Message storing error"
      );
    return true;
  });
};

export const setLocalStorage = isBrowser && setData;

export const getLocalStorageItem = isBrowser && getItem;

export const removeLocalStorageItem = isBrowser && removeItem;

export const getUser = () => isBrowser && getItem("user");

export const getAccessToken = () => isBrowser && getItem("access_token");

export const isLoggedIn = () => isBrowser && !!getItem("access_token");

export const logout = () => {
  clearData();
  return isLoggedIn();
};

export const signUp = (user) => {
  return axios
    .post(`${api.base}${api.signup}`, {
      user,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.error === "User already exists") {
        throw new Error("User already exists");
      }
      return getUserData(res.data.result.access_token);
    });
};

export const facebookLogin = (user) => {
  return axios({
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: `${api.base}${api.facebook}`,
    data: {
      accessToken: user.accessToken,
      language: "en",
    },
  })
    .then((res) => {
      setData({
        access_token: res.data.result.access_token,
      });
      return axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${res.data.result.access_token}`,
        },
        url: `${api.base}${api.user}`,
      });
    })
    .then((response) => {
      setData({ user: response.data.result });
    });
};
